import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentItemEditingChangeAction } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { logErrorToMonitoringTool } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  Content_Editing_DiscardNewVersionFailed,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_DiscardNewVersionStarted,
} from '../../../../constants/editorActionTypes.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import { DiscardNewVersionErrorMessage } from '../../constants/uiConstants.ts';
import { IParsedItemVariant } from '../../utils/parseContentItem.ts';
import {
  ItemWithVariantAndFilterAndDataPayload,
  contentItemEditingSidebarSectionCleanedUp,
} from '../contentItemEditingActions.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

interface IDeps {
  readonly trackUserEvent: TrackUserEventAction;
  readonly contentItemRepository: {
    readonly discardNewVersion: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
  readonly loadContentItemUsage: (itemId: Uuid, variantId: Uuid) => ThunkPromise;
}

const started = () => ({ type: Content_Editing_DiscardNewVersionStarted }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Content_Editing_DiscardNewVersionFailed,
    payload: { errorMessage },
  }) as const;

const finished = (payload: ItemWithVariantAndFilterAndDataPayload) =>
  ({
    type: Content_Editing_DiscardNewVersionFinished,
    payload,
  }) as const;

export type DiscardNewVersionActionTypes = ReturnType<
  typeof started | typeof failed | typeof finished
>;

export const discardNewVersionActionCreator =
  (deps: IDeps) => (): ThunkPromise => async (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: {
        editedContentItemVariant,
        listingUi: { filter, orderBy },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = state;

    if (!editedContentItemVariant) {
      throw InvariantException('editedContentItemVariant is falsy');
    }

    const {
      id: { itemId, variantId },
    } = editedContentItemVariant;

    dispatch(started());
    try {
      const itemWithVariant = await deps.contentItemRepository.discardNewVersion(itemId, variantId);
      const variantData = deps.parseContentItemVariant(itemWithVariant);
      const loadContentItemUsagePromise = dispatch(deps.loadContentItemUsage(itemId, variantId));
      await dispatch(
        deps.loadRelatedContentItemElementsData(
          itemId,
          variantId,
          variantData.editedContentItemVariantElements,
          null,
        ),
      );
      const compiledVariant = {
        ...variantData,
        editedContentItemVariantElements: variantData.editedContentItemVariantElements,
      };

      await loadContentItemUsagePromise;
      dispatch(
        finished({
          itemWithVariant,
          itemVariantData: compiledVariant,
          filter,
          orderBy,
          usedSearchMethod,
        }),
      );

      dispatch(
        deps.trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingChangeAction.DiscardNewVersion,
          origin: getModalDialogActionOrigin(state),
        }),
      );

      dispatch(contentItemEditingSidebarSectionCleanedUp());
    } catch (error) {
      logErrorToMonitoringTool('Error during discarding new version of an item variant', error);
      dispatch(failed(DiscardNewVersionErrorMessage));
    }
  };
