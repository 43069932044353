import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logErrorToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import {
  User_RevokeInvite_Failed,
  User_RevokeInvite_Finished,
  User_RevokeInvite_Started,
} from '../../constants/usersActionTypes.ts';

const started = (userId: Uuid) =>
  ({
    type: User_RevokeInvite_Started,
    payload: { userId },
  }) as const;

const finished = (userId: Uuid) =>
  ({
    type: User_RevokeInvite_Finished,
    payload: { userId },
  }) as const;

const failed = (userId: Uuid) =>
  ({
    type: User_RevokeInvite_Failed,
    payload: { userId },
  }) as const;

export type RevokeActionTypes = ReturnType<typeof started | typeof finished | typeof failed>;

interface IDeps {
  readonly loadExistingUsers: () => ThunkPromise;
  readonly loadProjectContributors: () => ThunkPromise;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid) => ThunkPromise;
  readonly peopleRepository: Pick<IPeopleRepository, 'revokeInvite'>;
}

export const createRevokeInviteAction =
  (deps: IDeps) =>
  (userId: Uuid, subscriptionId: Uuid, onSuccess?: () => void): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started(userId));

    try {
      await deps.peopleRepository.revokeInvite(userId);

      dispatch(finished(userId));
      onSuccess?.();

      await Promise.all([
        dispatch(deps.loadExistingUsers()),
        dispatch(deps.loadProjectContributors()),
        dispatch(deps.loadSubscriptionUsage(subscriptionId)),
      ]);
    } catch (e) {
      logErrorToMonitoringTool('revokeInvite.ts', e);
      dispatch(failed(userId));
    }
  };
