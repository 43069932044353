import { InvariantException } from '@kontent-ai/errors';
import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { logErrorToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { createCollectionFromServerModel } from '../../../../../data/models/collections/Collection.ts';
import { getAllProjectsMemoized } from '../../../../projects/selectors/projectSelectors.ts';
import { UserProjectSettings as Component } from '../../components/UserDetail/UserProjectSettings.tsx';
import { getUserProjectSettingsViewModel } from '../../models/UserProjectSettingsViewModel.ts';
import { CollectionsByProjectMap } from '../../models/collectionsByProjectMap.type.ts';
import {
  getProjectIdsFromSettings,
  getUserProjectSettings,
  getUserProjectSettingsActiveStatus,
} from '../../selectors/subscriptionUsersUsageSelectors.ts';
import { ProjectSettingsSearchResult } from '../../utils/userProjectSettingsFilterUtils.ts';

const useGetCollectionsByProject = (
  projectIds: ReadonlyArray<Uuid>,
  shouldFetchData: boolean,
): CollectionsByProjectMap => {
  const [collections, setCollections] = useState<CollectionsByProjectMap>(new Map());

  useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    const fetchCollections = makeCancellablePromise(() => {
      const collectionsByProject = projectIds.map(async (projectId) => {
        const data =
          await repositoryCollection.collectionsRepository.getProjectCollections(projectId);
        return [projectId, data.collections.map(createCollectionFromServerModel)] as const;
      });
      return Promise.all(collectionsByProject);
    })
      .then((collectionsByProject) => setCollections(new Map(collectionsByProject)))
      .catch(swallowCancelledPromiseError)
      .catch((e) => logErrorToMonitoringTool('useGetCollectionsByProject failed', e));

    return () => fetchCollections.cancel();
  }, [shouldFetchData, projectIds]);

  return collections;
};

type UserProjectSettingsContainerProps = {
  readonly searchResult: ProjectSettingsSearchResult;
  readonly userId: Uuid;
  readonly isSingleProject: boolean;

  readonly onNavigateBack: () => void;
};

export const UserProjectSettings: React.FC<UserProjectSettingsContainerProps> = ({
  userId,
  searchResult,
  isSingleProject,
  onNavigateBack,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onToggleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const allUsersUsages = useSelector((s) => s.data.subscriptions.subscriptionUsersUsage);
  if (!allUsersUsages) {
    throw InvariantException('UserProjectSettings.tsx: SubscriptionUsersUsage not loaded.');
  }

  const userProjectSettings = getUserProjectSettings(
    allUsersUsages.subscriptionUsers,
    userId,
    searchResult.project.projectId,
  );
  if (!userProjectSettings) {
    throw InvariantException(
      `UserProjectSettingsContainer.tsx: Cannot find project settings for user id ${userId}.`,
    );
  }

  const allCollections = useGetCollectionsByProject(
    getProjectIdsFromSettings(userProjectSettings),
    isExpanded,
  );
  const allProjects = useSelector((s) => getAllProjectsMemoized(s.data.projects.byId));

  const readyToCreateViewModel = isExpanded && allCollections.size > 0;
  const userProjectSettingsViewModel = readyToCreateViewModel
    ? getUserProjectSettingsViewModel(userProjectSettings, allProjects, allCollections)
    : undefined;

  const activeStatus = getUserProjectSettingsActiveStatus(
    allUsersUsages.subscriptionUsers,
    userId,
    userProjectSettings,
  );

  return (
    <Component
      activeStatus={activeStatus}
      isExpanded={isExpanded}
      masterProject={searchResult.project}
      onToggleExpand={onToggleExpand}
      projectSettings={userProjectSettingsViewModel}
      searchResult={searchResult}
      userId={userId}
      isSingleProject={isSingleProject}
      onNavigateBack={onNavigateBack}
    />
  );
};

UserProjectSettings.displayName = 'UserProjectSettings';
