import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logErrorToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { ISpacesRepository } from '../../../../../repositories/interfaces/ISpacesRepository.type.ts';
import { ISpaceServerResponseModel } from '../../../../../repositories/serverModels/SpacesServerModel.type.ts';
import {
  Spaces_Restore_Failed,
  Spaces_Restore_Started,
  Spaces_Restore_Succeeded,
} from '../../constants/spacesActionTypes.ts';
import { SpacesActionFailedMessage } from '../../constants/spacesUiConstants.ts';

type Deps = {
  readonly spacesRepository: Pick<ISpacesRepository, 'restore'>;
};

const started = () =>
  ({
    type: Spaces_Restore_Started,
  }) as const;

const restored = (space: ISpaceServerResponseModel) =>
  ({
    type: Spaces_Restore_Succeeded,
    payload: {
      space,
    },
  }) as const;

const failed = () =>
  ({
    type: Spaces_Restore_Failed,
    payload: {
      errorMessage: SpacesActionFailedMessage`restore`,
    },
  }) as const;

export type RestoreSpaceActionType = ReturnType<typeof started | typeof restored | typeof failed>;

export const createRestoreSpaceAction =
  (deps: Deps) =>
  (spaceId: Uuid, onSuccess: () => void): ThunkPromise =>
  async (dispatch): Promise<void> => {
    dispatch(started());

    try {
      const space = await deps.spacesRepository.restore(spaceId);
      dispatch(restored(space));

      onSuccess();
    } catch (error) {
      logErrorToMonitoringTool('restoreSpace.ts', error);
      dispatch(failed());
    }
  };
