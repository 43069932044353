import { InvariantException } from '@kontent-ai/errors';
import { assert, Collection } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { MemoizedContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import { getMemoizedContentItemId } from '../../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { logErrorToMonitoringTool } from '../../../../../../_shared/utils/logError.ts';
import { ItemListOperationResultServerModel } from '../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { ContentItemListingOperationQueryModel } from '../../../../../contentInventory/content/models/filter/ContentItemListOperationQueryModel.type.ts';
import { LastCascadeAction } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  IContentItemVariantReference,
  getContentItemVariantReferenceFromListingItem,
} from '../../../../models/contentItem/ContentItemVariantReference.ts';
import { IParsedItemVariant } from '../../../ContentItemEditing/utils/parseContentItem.ts';
import { ILoadListingItemsAction } from '../../../LoadedItems/actions/thunks/loadListingItems.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeAction_UndoStarted,
} from '../../constants/cascadeModalActionTypes.ts';
import { CascadeActionUndoErrorMessage } from '../../constants/uiConstants.ts';
import { getFirstLevelChildrenItemIds } from '../../selectors/getFirstLevelChildrenItemIds.ts';
import {
  callBulkActionInChunks,
  emptyListOperationResult,
} from '../../utils/callBulkActionInChunks.ts';
import { groupItemIdsByVariantId } from '../../utils/groupContentItemIds.ts';

interface IDeps {
  readonly loadListingItems: ILoadListingItemsAction;
  readonly loadDefaultListingItems: ILoadListingItemsAction;
  readonly loadContentItemUsage: (itemId: Uuid, variantId: Uuid) => ThunkPromise;
  readonly contentItemRepository: {
    readonly getItemWithVariant: (
      itemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
    readonly unpublishVariants: (
      variantId: Uuid,
      query: ContentItemListingOperationQueryModel,
    ) => Promise<ItemListOperationResultServerModel>;
    readonly cancelScheduledPublishOfVariants: (
      variantId: Uuid,
      query: ContentItemListingOperationQueryModel,
    ) => Promise<ItemListOperationResultServerModel>;
  };

  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
}

const started = (
  editedItemName: string,
  totalNumberOfAffectedItems: number,
  action: LastCascadeAction,
) =>
  ({
    type: ContentEditing_CascadeAction_UndoStarted,
    payload: {
      editedItemName,
      totalNumberOfAffectedItems,
      action,
    },
  }) as const;

const finished = (payload: {
  readonly defaultVariantReference: IContentItemVariantReference | null;
  readonly failedItemIds: ReadonlySet<MemoizedContentItemId>;
  readonly itemVariantData: IParsedItemVariant;
  readonly itemWithVariant: IContentItemWithVariantServerModel;
  readonly modifiedItemIds: ReadonlySet<MemoizedContentItemId>;
  readonly undoneAction: LastCascadeAction.Publish | LastCascadeAction.Schedule;
}) =>
  ({
    type: ContentEditing_CascadeAction_UndoFinished,
    payload,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: ContentEditing_CascadeAction_UndoFailed,
    payload: { errorMessage },
  }) as const;

export type UndoCascadeActionActionType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const undoCascadeActionCreator =
  (deps: IDeps) => (): ThunkPromise => async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const {
      contentApp: { editedContentItem, editedContentItemVariant },
      webSpotlightApp: { subpagesById },
    } = state;

    const { modifiedItemIds, lastAction } = state.contentApp.editorUi.cascadePublish.cascadeResult;
    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const editingDefaultVariant = selectedLanguageId === DefaultVariantId;

    if (!editedContentItem || !editedContentItemVariant) {
      throw InvariantException('editedContentItem or variant is falsy');
    }
    if (
      lastAction === LastCascadeAction.None ||
      lastAction === LastCascadeAction.UndoPublish ||
      lastAction === LastCascadeAction.UndoSchedule
    ) {
      throw InvariantException('lastAction is not something we could undo.');
    }

    const { id } = editedContentItemVariant;

    const undoAction =
      lastAction === LastCascadeAction.Publish
        ? deps.contentItemRepository.unpublishVariants
        : deps.contentItemRepository.cancelScheduledPublishOfVariants;

    try {
      dispatch(started(editedContentItem.name, modifiedItemIds.size, lastAction));

      const itemIdsToUndoByVariantId = groupItemIdsByVariantId(Array.from(modifiedItemIds));

      const idsToUndo = itemIdsToUndoByVariantId.get(selectedLanguageId) ?? [];
      const undoResult = await callBulkActionInChunks(Immutable.Set(idsToUndo), (ids) =>
        undoAction(selectedLanguageId, { includedItemIds: ids.toArray() }),
      );

      const firstLevelChildrenItemIds = Array.from(getFirstLevelChildrenItemIds(state)).map(
        ({ itemId }) => itemId,
      );
      const subpagesIdsToReload = subpagesById
        .toArray()
        .flat()
        .filter((subpageId) => idsToUndo.includes(subpageId));

      const itemIdsToReload = Array.from(
        new Set([
          editedContentItemVariant.id.itemId,
          ...firstLevelChildrenItemIds,
          ...undoResult.failedItemIds.toArray(),
          ...subpagesIdsToReload,
        ]),
      );

      // When undoing the cascade publishing of non-default variants with their default counterparts, we need to undo cascade publishing
      // for non-default variants first, so that they don't stay published with empty values when the default variants are unpublished.
      let defaultItemsToReload: ReadonlyArray<Uuid> = [];
      let defaultUndoResult = emptyListOperationResult;
      if (!editingDefaultVariant && itemIdsToUndoByVariantId.has(DefaultVariantId)) {
        const idsToPublish = itemIdsToUndoByVariantId.get(DefaultVariantId) ?? [];

        defaultUndoResult = await callBulkActionInChunks(Immutable.Set(idsToPublish), (ids) =>
          undoAction(DefaultVariantId, { includedItemIds: ids.toArray() }),
        );

        defaultItemsToReload = [...defaultUndoResult.failedItemIds.toArray(), id.itemId];
      }

      const [itemWithVariant, defaultListingItems] = await Promise.all([
        deps.contentItemRepository.getItemWithVariant(id.itemId, id.variantId),
        dispatch(deps.loadDefaultListingItems(defaultItemsToReload)),
        dispatch(deps.loadContentItemUsage(id.itemId, id.variantId)),
        dispatch(deps.loadListingItems(itemIdsToReload)),
      ]);

      const modified = Collection.addMany(
        new Set(
          defaultUndoResult.modifiedItemIds
            .toArray()
            .map((itemId: Uuid) => getMemoizedContentItemId(itemId, DefaultVariantId)),
        ),
        undoResult.modifiedItemIds
          .toArray()
          .map((itemId) => getMemoizedContentItemId(itemId, selectedLanguageId)),
      );

      const failedItemIds = Collection.addMany(
        new Set(
          defaultUndoResult.failedItemIds
            .toArray()
            .map((itemId: Uuid) => getMemoizedContentItemId(itemId, DefaultVariantId)),
        ),
        undoResult.failedItemIds
          .toArray()
          .map((itemId) => getMemoizedContentItemId(itemId, selectedLanguageId)),
      );

      const itemVariantData = deps.parseContentItemVariant(itemWithVariant);
      assert(defaultListingItems, () => 'Default listing items not loaded');
      const defaultVariantReference = getContentItemVariantReferenceFromListingItem(
        defaultListingItems.find((item) => item.variant?.id.itemId === id.itemId) ?? null,
      );

      dispatch(
        trackUserEvent(
          lastAction === LastCascadeAction.Publish
            ? TrackedEvent.CascadePublishUndo
            : TrackedEvent.CascadeScheduleUndo,
          {
            failedItemsCount: failedItemIds.size,
          },
        ),
      );

      dispatch(
        finished({
          defaultVariantReference,
          failedItemIds,
          itemVariantData,
          itemWithVariant,
          modifiedItemIds: modified,
          undoneAction: lastAction,
        }),
      );
    } catch (error) {
      logErrorToMonitoringTool('Error during undoing cascade publish/schedule', error);
      dispatch(failed(CascadeActionUndoErrorMessage));
    }
  };
