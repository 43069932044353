import { delay } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../_shared/actions/sharedActions.ts';
import { logErrorToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import { IStatusInfoMessage } from '../../../../contentInventory/assets/models/IStatusInfoMessage.type.ts';
import {
  Taxonomy_Groups_RestorationInfoFaded,
  Taxonomy_Groups_Restored,
  Taxonomy_Groups_Restoring,
} from '../../constants/taxonomyActionTypes.ts';
import { RestoreTaxonomyGroupErrorMessage } from '../../constants/uiConstants.ts';
import { taxonomyGroupsLoaded } from '../taxonomyActions.ts';

interface ICreateRestoreArchivedTaxonomyGroupsActionDependencies {
  readonly taxonomyRepository: {
    readonly restoreTaxonomyGroups: (
      groupIds: Immutable.Set<Uuid>,
    ) => Promise<Array<ITaxonomyGroupServerModel>>;
    readonly getTaxonomyGroups: () => Promise<Array<ITaxonomyGroupServerModel>>;
  };
  readonly loadTaxonomyGroups: () => ThunkPromise;
}

const taxonomyGroupsRestored = (groupIds: Immutable.Set<Uuid>) =>
  ({
    type: Taxonomy_Groups_Restored,
    payload: { groupIds },
  }) as const;

const taxonomyGroupsRestoreStarted = () =>
  ({
    type: Taxonomy_Groups_Restoring,
  }) as const;

const taxonomyGroupRestorationFaded = (originalStatusInfoMessage: IStatusInfoMessage) =>
  ({
    type: Taxonomy_Groups_RestorationInfoFaded,
    payload: { originalStatusInfoMessage },
  }) as const;

export type RestoreArchivedTaxonomyGroupsActionsType = ReturnType<
  | typeof taxonomyGroupsRestored
  | typeof taxonomyGroupRestorationFaded
  | typeof taxonomyGroupsRestoreStarted
>;

export const createRestoreArchivedTaxonomyGroupsAction =
  (deps: ICreateRestoreArchivedTaxonomyGroupsActionDependencies) =>
  (groupIds: Immutable.Set<Uuid>): ThunkPromise =>
  async (dispatch: Dispatch, getStore: GetState): Promise<void> => {
    dispatch(taxonomyGroupsRestoreStarted());
    try {
      await deps.taxonomyRepository.restoreTaxonomyGroups(groupIds);

      dispatch(taxonomyGroupsRestored(groupIds));
      const { taxonomyGroupListStatusInfoMessage } = getStore().taxonomyApp;

      delay(5000).then(() =>
        dispatch(taxonomyGroupRestorationFaded(taxonomyGroupListStatusInfoMessage)),
      );

      dispatch(deps.loadTaxonomyGroups());

      const freshGroups = await deps.taxonomyRepository.getTaxonomyGroups();
      dispatch(taxonomyGroupsLoaded(freshGroups));
    } catch (error) {
      dispatch(showErrorNotification(RestoreTaxonomyGroupErrorMessage));
      logErrorToMonitoringTool('Error while restoring a taxonomy group', error);
    }
  };
