import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { upsertUserProperty } from '../../../../_shared/actions/thunkSharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  EditContentTypeRoute,
  EditContentTypeRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { LongProcessingChangesWarningDismissedServerKey } from '../../../../_shared/models/UserPropertiesServerKeys.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { logErrorToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  focusFirstInvalidElement,
  validateTypeElement,
} from '../../shared/actions/thunkSharedContentModelsActions.ts';
import { getElementsWithAffectedLocalization } from '../../shared/utils/getElementsWithAffectedLocalization.ts';
import { isTypeValid } from '../../shared/utils/typeValidationUtils.ts';
import { contentTypeInvalidElementShowWarning } from '../actions/contentTypesActions.ts';
import { createContentType, initContentTypeCreator } from '../actions/thunkContentTypesActions.ts';
import { ContentTypeEditor as ContentTypeEditorComponent } from '../components/ContentTypeEditor.tsx';
import { getContentTypeEditorState } from '../selectors/getContentTypeEditorState.ts';

const getValidationResults = compose((t) => t.validationResults, getContentTypeEditorState);
const getHasUnsavedChanges = compose((t) => t.editedTypeIsModified, getContentTypeEditorState);
const getIsBeingSaved = compose((t) => t.typeIsBeingSaved, getContentTypeEditorState);
const getLoadingStatus = compose((t) => t.loadingStatus, getContentTypeEditorState);

export const ContentTypeCreator = () => {
  const [isInitThunkDone] = useThunkPromise(initContentTypeCreator);

  const dispatch = useDispatch();
  const onFocusFirstInvalidElement = () => {
    dispatch(focusFirstInvalidElement());
  };
  const validationResults = useSelector(getValidationResults);
  const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
  const isBeingSaved = useSelector(getIsBeingSaved);
  const currentProjectId = useSelector(getCurrentProjectId);
  const allSet = useSelector(
    (s) => isInitThunkDone && getLoadingStatus(s) === LoadingStatus.Loaded,
  );

  const editedType = useSelector((s) => s.contentModelsApp.typeEditor.editedType);
  const originalTypeElements = useSelector(
    (s) => s.data.contentTypes.byId.get(editedType.id)?.typeElements,
  );
  const elementsWithAffectedLocalization = useMemo(
    () => getElementsWithAffectedLocalization(editedType, originalTypeElements),
    [editedType, originalTypeElements],
  );

  const isValid = (editedContentType: IContentType): boolean => {
    const elementToValidationResults = new Map(
      editedContentType.typeElements.map((element) => [
        element.elementId,
        dispatch(validateTypeElement(element)),
      ]),
    );
    return isTypeValid(elementToValidationResults);
  };

  const history = useHistory();
  const saveContentType = async (): Promise<void> => {
    if (isValid(editedType)) {
      const createdContentType = await dispatch(createContentType(editedType));
      history.push(
        buildPath<EditContentTypeRouteParams>(EditContentTypeRoute, {
          projectId: currentProjectId,
          contentTypeId: createdContentType.id,
        }),
      );
    } else {
      dispatch(contentTypeInvalidElementShowWarning());
    }
  };

  const unsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    try {
      if (isValid(editedType)) {
        await dispatch(createContentType(editedType));
        onSuccess();
      } else {
        dispatch(contentTypeInvalidElementShowWarning());
        onFail();
      }
    } catch (error) {
      onFail();
      logErrorToMonitoringTool('ContentTypeCreator.tsx', error);
    }
  };

  const dismissLongProcessingChangesWarning = useCallback(
    () => dispatch(upsertUserProperty(LongProcessingChangesWarningDismissedServerKey, 'true')),
    [],
  );

  if (!allSet) {
    return <Loader />;
  }

  return (
    <ContentTypeEditorComponent
      focusTypeName
      elementsWithAffectedLocalization={elementsWithAffectedLocalization}
      saveContentType={saveContentType}
      onDismissLongProcessingChangesWarning={dismissLongProcessingChangesWarning}
      onUnsavedNavigation={unsavedNavigationHandler}
      hasUnsavedChanges={hasUnsavedChanges}
      isBeingSaved={isBeingSaved}
      onFocusFirstInvalidElement={onFocusFirstInvalidElement}
      hideLongProcessingChangesWarning
      showContentTypeUsedWithPublishedItemWarning={false}
      validationResults={validationResults}
    />
  );
};
