import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { UnpublishErrorMessage } from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentItemEditingChangeAction } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { logErrorToMonitoringTool } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import { IParsedItemVariant } from '../../utils/parseContentItem.ts';
import { prepareWorkflowStepTrackingData } from '../../utils/prepareWorkflowStepTrackingData.ts';
import {
  contentItemEditingSidebarSectionCleanedUp,
  unpublishContentItemVariantFailed,
  unpublishContentItemVariantFinished,
  unpublishContentItemVariantStarted,
} from '../contentItemEditingActions.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

interface IUnpublishContentItemVariantDependencies {
  readonly trackUserEvent: TrackUserEventAction;
  readonly contentItemRepository: {
    readonly getItemWithVariant: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
    readonly unpublishVariant: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
  readonly loadContentItemUsage: (itemId: Uuid, variantId: Uuid) => ThunkPromise;
}

export const unpublishContentItemVariantActionCreator =
  (deps: IUnpublishContentItemVariantDependencies) =>
  ({ itemId, variantId }: ContentItemId): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: {
        editedContentItemVariant,
        editorUi: { itemEditingModalDialog },
        listingUi: { filter, orderBy },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = state;

    if (!editedContentItemVariant) {
      throw InvariantException('editedContentItemVariant is falsy');
    }

    dispatch(unpublishContentItemVariantStarted());
    try {
      await deps.contentItemRepository.unpublishVariant(itemId, variantId);
      const itemWithVariant = await deps.contentItemRepository.getItemWithVariant(
        itemId,
        variantId,
      );
      const variantData = deps.parseContentItemVariant(itemWithVariant);

      const loadContentItemUsagePromise = dispatch(deps.loadContentItemUsage(itemId, variantId));

      await dispatch(
        deps.loadRelatedContentItemElementsData(
          itemId,
          variantId,
          variantData.editedContentItemVariantElements,
          null,
        ),
      );

      const compiledVariant = {
        ...variantData,
        editedContentItemVariantElements: variantData.editedContentItemVariantElements,
      };

      await loadContentItemUsagePromise;
      dispatch(
        unpublishContentItemVariantFinished({
          itemWithVariant,
          itemVariantData: compiledVariant,
          filter,
          orderBy,
          usedSearchMethod,
        }),
      );
      dispatch(
        deps.trackUserEvent(
          TrackedEvent.ContentItemEditing,
          prepareWorkflowStepTrackingData(compiledVariant, {
            action: ContentItemEditingChangeAction.Unpublish,
            origin: getModalDialogActionOrigin(state),
          }),
        ),
      );

      dispatch(contentItemEditingSidebarSectionCleanedUp());
    } catch (error) {
      logErrorToMonitoringTool('Error during unpublishing of an item variant', error);

      if (!itemEditingModalDialog.properties) {
        throw InvariantException(
          'No modal dialog properties when trying to open dialog after failed unpublish.',
        );
      }
      dispatch(
        unpublishContentItemVariantFailed(UnpublishErrorMessage, itemEditingModalDialog.properties),
      );
    }
  };
