import { createGuid } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../../../../_shared/actions/sharedActions.ts';
import { logErrorToMonitoringTool } from '../../../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  ContentListing_UndoPublishItems_Finished,
  ContentListing_UndoPublishItems_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { BulkUndoPublishErrorMessage } from '../../../constants/uiConstants.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFinished,
  createBulkActionStarted,
  resetStatusInfo,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface IUndoPublishContentItemsActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly loadListingContentItems: () => ThunkPromise;
}

const actionStarted = createBulkActionStarted<typeof ContentListing_UndoPublishItems_Started>(
  ContentListing_UndoPublishItems_Started,
);
const actionFinished = createBulkActionFinished<typeof ContentListing_UndoPublishItems_Finished>(
  ContentListing_UndoPublishItems_Finished,
);

export type UndoPublishContentItemsActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished
>;

export const undoPublishContentItemsActionCreator =
  (deps: IUndoPublishContentItemsActionDependencies) =>
  (variantId: Uuid, affectedIds: ReadonlyArray<Uuid>): ThunkPromise =>
  async (dispatch: Dispatch): Promise<any> => {
    const operationId = createGuid();
    dispatch(actionStarted({ operationId }));
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.unpublishVariants(variantId, {
        includedItemIds: affectedIds,
      });
      dispatch(actionFinished(createBulkActionResult(operationResult, variantId)));
    } catch (error) {
      logErrorToMonitoringTool('Error while undoing publish on content listing', error);
      dispatch(resetStatusInfo());
      dispatch(showErrorNotification(BulkUndoPublishErrorMessage));
    }

    dispatch(deps.loadListingContentItems());
  };
