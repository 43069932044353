import { Dispatch, GetState, ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { UserPropertyServerModel } from '../../../repositories/serverModels/UserPropertyServerModel.type.ts';
import {
  Shared_UserProperties_UpsertStarted,
  Shared_UserProperties_UpsertSucceeded,
} from '../../constants/sharedActionTypes.ts';
import { UserPropertyServerKey } from '../../models/UserPropertiesModel.ts';
import { logErrorToMonitoringTool } from '../../utils/logError.ts';

interface IDependencies {
  readonly accountRepository: {
    readonly upsertProperty: (
      userId: Uuid,
      propertyKey: string,
      propertyValue: string,
    ) => Promise<UserPropertyServerModel>;
  };
}

const upsertPropertyStarted = (propertyKey: UserPropertyServerKey, propertyValue: string) =>
  ({
    type: Shared_UserProperties_UpsertStarted,
    payload: {
      propertyKey,
      propertyValue,
    },
  }) as const;

const upsertPropertySucceeded = (propertyKey: UserPropertyServerKey, propertyValue: string) =>
  ({
    type: Shared_UserProperties_UpsertSucceeded,
    payload: {
      propertyKey,
      propertyValue,
    },
  }) as const;

export type UpsertUserPropertyActionsType = ReturnType<
  typeof upsertPropertySucceeded | typeof upsertPropertyStarted
>;

export const createUpsertPropertyAction =
  (deps: IDependencies) =>
  (propertyKey: UserPropertyServerKey, propertyValue: string): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      data: {
        user: {
          info: { userId },
        },
      },
    } = getState();

    try {
      dispatch(upsertPropertyStarted(propertyKey, propertyValue));

      await deps.accountRepository.upsertProperty(userId, propertyKey, propertyValue);
      dispatch(upsertPropertySucceeded(propertyKey, propertyValue));
    } catch (e) {
      logErrorToMonitoringTool('upsertUserProperty.ts', e);
    }
  };
