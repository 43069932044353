import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { logErrorToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import {
  ICommentThread,
  createCommentThreadDomainModel,
} from '../../../itemEditor/models/comments/CommentThreads.ts';
import { ContentStatusCommentsTable as ContentStatusCommentsTableComponent } from '../components/ContentStatusCommentsTable.tsx';

const { commentRepository } = repositoryCollection;

type ContentStatusCommentsTableContainerProps = Readonly<{
  itemId: Uuid;
  variantId: Uuid;
}>;

const ContentStatusCommentsTableContainer: React.FC<ContentStatusCommentsTableContainerProps> = ({
  itemId,
  variantId,
}) => {
  const [commentThreads, setCommentThreads] = useState<ReadonlyArray<ICommentThread>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    const { cancel } = makeCancellablePromise(
      async () => await commentRepository.getAll(itemId, variantId, true),
    )
      .then((response) => setCommentThreads(response.map(createCommentThreadDomainModel)))
      .catch(swallowCancelledPromiseError)
      .catch((error) => {
        logErrorToMonitoringTool('ContentStatusCommentsTable.tsx', error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return cancel;
  }, [itemId, variantId]);

  return (
    <ContentStatusCommentsTableComponent
      itemId={itemId}
      variantId={variantId}
      isLoading={isLoading}
      commentThreads={commentThreads}
    />
  );
};

ContentStatusCommentsTableContainer.displayName = 'ContentItemCommentsTableContainer';
export { ContentStatusCommentsTableContainer as ContentStatusCommentsTable };
