import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { logErrorToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import {
  User_ToggleActiveState_Failed,
  User_ToggleActiveState_Finished,
  User_ToggleActiveState_Started,
} from '../../constants/usersActionTypes.ts';

interface IDeps {
  readonly peopleRepository: Pick<
    IPeopleRepository,
    'activateContributor' | 'deactivateContributor'
  >;
  readonly loadProjectContributors: () => ThunkPromise;
  readonly trackUserEvent: TrackUserEventAction;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid) => ThunkPromise;
  readonly loadUsersCountedInUsage: () => ThunkPromise;
}

const started = (userId: Uuid, activate: boolean) =>
  ({
    type: User_ToggleActiveState_Started,
    payload: {
      userId,
      activate,
    },
  }) as const;

const finished = (userId: Uuid, activate: boolean) =>
  ({
    type: User_ToggleActiveState_Finished,
    payload: {
      userId,
      activate,
    },
  }) as const;

const failed = (userId: Uuid, activate: boolean, triggeredFromUsersOverview: boolean) =>
  ({
    type: User_ToggleActiveState_Failed,
    payload: {
      userId,
      activate,
      triggeredFromUsersOverview,
    },
  }) as const;

export type ToggleUserActiveStateActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createToggleUserActiveStateAction =
  (deps: IDeps) =>
  (
    userId: Uuid,
    activate: boolean,
    subscriptionId: Uuid,
    triggeredFromUsersOverview: boolean,
  ): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started(userId, activate));
    const eventData = {
      'user-id': userId,
    };

    try {
      if (activate) {
        await deps.peopleRepository.activateContributor(userId);
        dispatch(deps.trackUserEvent(TrackedEvent.ContributorActivated, eventData));
      } else {
        await deps.peopleRepository.deactivateContributor(userId);
        dispatch(deps.trackUserEvent(TrackedEvent.ContributorDeactivated, eventData));
      }
      dispatch(finished(userId, activate));
    } catch (e) {
      logErrorToMonitoringTool('toggleUserActiveState.ts', e);
      dispatch(failed(userId, activate, triggeredFromUsersOverview));
    } finally {
      await dispatch(deps.loadProjectContributors());
      await dispatch(deps.loadSubscriptionUsage(subscriptionId));
      await dispatch(deps.loadUsersCountedInUsage());
    }
  };
