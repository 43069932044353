import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logErrorToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import {
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed,
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Finished,
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Started,
} from '../../constants/actionTypes.ts';

interface IDeps {
  readonly loadSubscriptionUsage: (subscriptionId: Uuid) => ThunkPromise;
  readonly loadSubscriptionUsersUsage: () => ThunkPromise;
  readonly peopleRepository: Pick<
    IPeopleRepository,
    | 'activateContributorOnAllSubscriptionProjects'
    | 'deactivateContributorOnAllSubscriptionProjects'
  >;
}

const started = (userId: UserId, subscriptionId: Uuid, activate: boolean) =>
  ({
    type: SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Started,
    payload: {
      userId,
      subscriptionId,
      activate,
    },
  }) as const;

const finished = () =>
  ({
    type: SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Finished,
  }) as const;

const failed = (
  userId: UserId,
  subscriptionId: Uuid,
  activate: boolean,
  triggeredFromUsersOverview: boolean,
) =>
  ({
    type: SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed,
    payload: {
      userId,
      subscriptionId,
      activate,
      triggeredFromUsersOverview,
    },
  }) as const;

export type TriggerUserActiveStateOnAllSubscriptionProjectsActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createTriggerUserActiveStateOnAllSubscriptionProjectsAction =
  (deps: IDeps) =>
  (
    userId: UserId,
    subscriptionId: Uuid,
    activate: boolean,
    triggeredFromUsersOverview: boolean,
  ): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started(userId, subscriptionId, activate));

    try {
      if (activate) {
        await deps.peopleRepository.activateContributorOnAllSubscriptionProjects(
          userId,
          subscriptionId,
        );
      } else {
        await deps.peopleRepository.deactivateContributorOnAllSubscriptionProjects(
          userId,
          subscriptionId,
        );
      }
      dispatch(finished());
    } catch (e) {
      logErrorToMonitoringTool('triggerUserActiveStateOnAllSubscriptionProjects.ts', e);
      dispatch(failed(userId, subscriptionId, activate, triggeredFromUsersOverview));
    } finally {
      dispatch(deps.loadSubscriptionUsage(subscriptionId));
      dispatch(deps.loadSubscriptionUsersUsage());
    }
  };
