import { Button } from '@kontent-ai/component-library/Button';
import loadScript from 'load-script';
import React, { useCallback, useEffect, useState } from 'react';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ShortcutSymbols } from '../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  logErrorMessageToMonitoringTool,
  logErrorToMonitoringTool,
} from '../../../../_shared/utils/logError.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';

interface IPopupClosedArgs {
  readonly id: string;
  readonly reference: string;
}

interface IWindowWithFastSpring {
  fastspring?: any;
  onDataPopupClosed?: (order?: IPopupClosedArgs) => void;
  onDataCallback?: (data: any) => void;
}

export interface IFastSpringWidgetOwnProps {
  readonly buttonIsPrimary?: boolean;
  readonly buttonText: string;
  readonly dataUiAction?: DataUiAction;
  readonly customButtonClassName?: string;
  readonly children?: never;
}

export interface IFastSpringWidgetProps {
  readonly baseUrl: string;
  readonly clientUrl: string;
  readonly currentUserId: UserId | null;
  readonly isDevelop: boolean;
  readonly onFastSpringAccountConnected: (subscriptionId: Uuid) => void;
  readonly subscriptionId: Uuid;
}

type FastSpringWidgetProps = IFastSpringWidgetProps & IFastSpringWidgetOwnProps;

const loadFastSpring = (isDevelop: boolean, baseUrl: string) => {
  loadScript(
    `https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.3/fastspring-builder.${
      isDevelop ? '' : 'min.'
    }js`,
    {
      async: false,
      attrs: {
        id: 'fsc-api',
        'data-debug': isDevelop.toString(),
        'data-storefront': `${baseUrl}/popup-payment`,
        'data-data-callback': 'onDataCallback',
        'data-popup-closed': 'onDataPopupClosed',
      },
    },
    (err: Error) => {
      if (err) {
        logErrorToMonitoringTool('FastSpring script library could not be loaded', err);
      }
    },
  );
};

export const FastSpringWidget: React.FC<FastSpringWidgetProps> = ({
  baseUrl,
  onFastSpringAccountConnected,
  isDevelop,
  buttonText,
  customButtonClassName,
  buttonIsPrimary,
  clientUrl,
  dataUiAction,
  subscriptionId,
  currentUserId,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const onDataPopupClosed = useCallback(
    (order?: IPopupClosedArgs) => {
      if (order) {
        onFastSpringAccountConnected(subscriptionId);
      }
    },
    [onFastSpringAccountConnected, subscriptionId],
  );

  const onDataCallback = useCallback(() => {
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    setIsInitialized(false);
    loadFastSpring(isDevelop, baseUrl);
  }, [isDevelop, baseUrl]);

  useEffect(() => {
    const w = self as IWindowWithFastSpring;
    w.onDataCallback = onDataCallback;
    return () => {
      w.onDataCallback = undefined;
    };
  }, [onDataCallback]);

  useEffect(() => {
    const w = self as IWindowWithFastSpring;
    w.onDataPopupClosed = onDataPopupClosed;
    return () => {
      w.onDataPopupClosed = undefined;
    };
  }, [onDataPopupClosed]);

  const openFastspringStorePopup = useCallback((): void => {
    if (!isInitialized) {
      logErrorMessageToMonitoringTool('Cannot instantiate fast spring before it’s initialized.');
    }

    if (!subscriptionId || isEmptyOrWhitespace(subscriptionId)) {
      logErrorMessageToMonitoringTool('No subscription given');
    }

    const fsBuilder = (window as IWindowWithFastSpring).fastspring.builder;
    const payload = {
      products: [
        {
          path: 'cloud-subscription',
          quantity: 1,
        },
      ],
      tags: {
        subscriptionId,
        activatedByUserId: currentUserId,
        clientUrl,
      },
      checkout: true,
    };

    fsBuilder.push(payload);
  }, [clientUrl, currentUserId, isInitialized, subscriptionId]);

  return (
    <HotkeysHandler
      handlers={{
        onEnter: isInitialized ? openFastspringStorePopup : undefined,
      }}
      mode={HotkeysMode.WindowOnly}
    >
      <Button
        buttonDisplay="block"
        buttonStyle={buttonIsPrimary ? 'primary' : 'secondary'}
        disabled={!isInitialized}
        onClick={isInitialized ? openFastspringStorePopup : undefined}
        tooltipPlacement="top"
        tooltipShortcuts={isInitialized ? ShortcutSymbols.Enter : undefined}
        tooltipText={isInitialized ? buttonText : 'Initializing payment gateway…'}
        {...{ className: customButtonClassName }}
        {...getDataUiActionAttribute(dataUiAction)}
      >
        {buttonText}
      </Button>
    </HotkeysHandler>
  );
};

FastSpringWidget.displayName = 'FastSpringWidget';
