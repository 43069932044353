import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { ProgressCallback } from '../../../../../_shared/utils/ajax.ts';
import { logErrorToMonitoringTool } from '../../../../../_shared/utils/logError.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../repositories/serverModels/ServerApiError.ts';
import { IAssetService } from '../../../content/features/Asset/services/assetService.ts';
import {
  AssetLibrary_Asset_ReplaceFailed,
  AssetLibrary_Asset_ReplaceFinished,
  AssetLibrary_Asset_ReplaceStarted,
} from '../../constants/assetLibraryActionTypes.ts';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';
import { isAssetReplaceFeatureAvailable } from '../../selectors/isAssetReplaceFeatureAvailable.ts';
import { showUnsupportedAssetFileTypeError } from '../assetLibraryActions.ts';
import { IAssetUploadResult } from './createAssets.ts';

const assetReplaceStarted = (assetId: Uuid) =>
  ({
    type: AssetLibrary_Asset_ReplaceStarted,
    payload: { assetId },
  }) as const;

const assetReplaceFailed = (oldAssetId: Uuid) =>
  ({
    type: AssetLibrary_Asset_ReplaceFailed,
    payload: {
      oldAssetId,
    },
  }) as const;

const assetReplaceFinished = (uploadResult: IAssetUploadResult) =>
  ({
    type: AssetLibrary_Asset_ReplaceFinished,
    payload: uploadResult,
  }) as const;

export type ReplaceAssetActionsType = ReturnType<
  typeof assetReplaceFailed | typeof assetReplaceFinished | typeof assetReplaceStarted
>;

type Deps = {
  readonly assetService: IAssetService;
  readonly getFileUploadProgressHandler: (assetId: Uuid, dispatch: Dispatch) => ProgressCallback;
  readonly trackUserEvent: TrackUserEventAction;
};

export const createReplaceAssetAction =
  (deps: Deps) =>
  (
    assetId: Uuid,
    files: ReadonlyArray<FileWithThumbnail>,
  ): ThunkPromise<IAssetUploadResult | null> =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      if (!isAssetReplaceFeatureAvailable(getState())) {
        throw InvariantException('Asset replace feature is not available for current project');
      }

      const file = files?.[0];
      if (!file) {
        throw InvariantException('No file for replace provided found!');
      }

      dispatch(assetReplaceStarted(assetId));

      const handleFileUploadProgress = deps.getFileUploadProgressHandler(assetId, dispatch);

      const replacedAsset = await deps.assetService.replaceAsset(
        assetId,
        file,
        handleFileUploadProgress,
      );

      const uploadResult: IAssetUploadResult = {
        oldAssetId: assetId,
        newAsset: replacedAsset,
      };

      dispatch(assetReplaceFinished(uploadResult));

      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetReplaced, {
          size: replacedAsset.fileSize,
          type: replacedAsset.type,
          'asset-id': replacedAsset.id,
        }),
      );

      return uploadResult;
    } catch (e) {
      const apiError = tryParseApiError(e);
      if (apiError?.code === ServerApiErrorCode.UnsupportedAssetFileType) {
        dispatch(showUnsupportedAssetFileTypeError());
      }

      dispatch(assetReplaceFailed(assetId));
      logErrorToMonitoringTool('replaceAsset.ts', e);
      return null;
    }
  };
