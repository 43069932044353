import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import { IUserRepository } from '../../../repositories/interfaces/IUserRepository.type.ts';
import { IntercomMessageServerModel } from '../../../repositories/serverModels/IntercomMessageServerModel.type.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../models/TrackUserEvent.type.ts';
import { FeedbackTextModalActionType } from '../../models/TrackUserEventData.ts';
import { logErrorToMonitoringTool } from '../../utils/logError.ts';
import { modalDismissed } from '../sharedActions.ts';

interface IDeps {
  readonly userRepository: Pick<IUserRepository, 'sendIntercomMessage'>;
  readonly trackUserEvent: TrackUserEventAction;
}

export const createSendUserFeedbackAction =
  ({ userRepository, trackUserEvent }: IDeps) =>
  (feedbackText: string, isContactMeChecked: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(
      trackUserEvent(TrackedEvent.FeedbackTextModal, {
        action: FeedbackTextModalActionType.Send,
        message: feedbackText,
        isContactMeChecked,
      }),
    );
    dispatch(modalDismissed());

    try {
      await userRepository.sendIntercomMessage(createMessage(feedbackText, isContactMeChecked));
    } catch (e) {
      logErrorToMonitoringTool('sendUserFeedback.ts', e);
    }
  };

const createMessage = (
  feedbackText: string,
  isContactMeChecked: boolean,
): IntercomMessageServerModel => ({
  text: `This message was automatically generated by Kontent.ai.

Feedback text from "Send your feedback" modal:
${feedbackText}

Does the user want to be contacted: ${isContactMeChecked ? 'yes' : 'no'}`,
});
