import { Dispatch, ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../models/TrackUserEvent.type.ts';
import { ProductUpdateOpenedAtServerKey } from '../../models/UserPropertiesServerKeys.ts';
import { logErrorToMonitoringTool } from '../../utils/logError.ts';

interface IDependencies {
  readonly upsertUserProperty: (propertyKey: string, propertyValue: string) => ThunkPromise;
  readonly trackUserEvent: TrackUserEventAction;
}

export const createProductUpdateLinkOpenedAction =
  ({ upsertUserProperty, trackUserEvent }: IDependencies) =>
  (url: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const visitedAt = new Date().toUTCString();

    try {
      await dispatch(upsertUserProperty(ProductUpdateOpenedAtServerKey, visitedAt));
      dispatch(
        trackUserEvent(TrackedEvent.FeatureUsed, {
          feature: 'product-update--opened',
          info: url,
        }),
      );
    } catch (e) {
      logErrorToMonitoringTool('productUpdateLinkOpened.ts', e);
    }
  };
