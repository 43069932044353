import { NotificationBarAlert } from '@kontent-ai/component-library/NotificationBar';
import { always } from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { DialogState } from '../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { FullScreenModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { IDialogActionWithMoreOptions } from '../../../../../component-library/components/Dialogs/components/DialogFooterAction.tsx';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { ShortcutSymbols } from '../../../../_shared/constants/shortcutSymbols.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { getEditedContentItemVariantId } from '../../../../_shared/selectors/getEditedContentItemVariant.ts';
import {
  DataUiAction,
  DataUiAssetRenditionAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { logErrorToMonitoringTool } from '../../../../_shared/utils/logError.ts';
import {
  IAssetRendition,
  IImageTransformation,
  mapAssetRenditionFromServerModel,
  mapImageTransformationToRequestServerModel,
} from '../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { IImage, isImage } from '../../../../data/models/assets/Image.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../repositories/serverModels/ServerApiError.ts';
import {
  assetRenditionCreated,
  assetRenditionUpdated,
} from '../../../contentInventory/assets/actions/assetLibraryActions.ts';
import { AssetLimitations } from '../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { revalidateAssetAndRichTextElements } from '../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { AssetRenditionRemoveConfirmationDialog } from './AssetRenditionRemoveConfirmationDialog.tsx';
import { ImageTransformationEditor } from './ImageTransformationEditor.tsx';
import { MaxDimensionsLimits, createInitialOutputDimensions } from './utils/dimensionUtils.ts';

type Props = {
  readonly asset: IImage;
  readonly existingRendition: IAssetRendition | null;
  readonly isDataLoading: boolean;
  readonly isOpen: boolean;
  readonly limitations: AssetLimitations;
  readonly onClose: () => void;
  readonly onCreated: (renditionId: Uuid) => void;
  readonly onRemove: () => void;
  readonly onUpdated: () => void;
};

export const AssetRenditionDialog: React.FC<Props> = ({
  asset,
  existingRendition,
  isDataLoading,
  isOpen,
  limitations,
  onClose,
  onCreated,
  onRemove,
  onUpdated,
}) => {
  const dispatch = useDispatch();

  const editedContentItemId = useSelector(getEditedContentItemVariantId);

  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [areDimensionsValid, setAreDimensionsValid] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [transformation, setTransformation] = useState<IImageTransformation | null>(null);

  useEffect(() => {
    if (isDataLoading) {
      setTransformation(null);
    } else if (existingRendition) {
      setTransformation(existingRendition.transformation);
    } else {
      setTransformation(createInitialTransformation(asset, limitations));
    }
  }, [asset, existingRendition, isDataLoading, limitations]);

  useEffect(() => {
    if (isOpen) {
      setErrorMessage(null);
      setAreDimensionsValid(true);
    }
  }, [isOpen]);

  const isRenditionReusedOutsideEditedItem = async (existingRenditionId: Uuid) => {
    if (!editedContentItemId) {
      return false;
    }
    const renditionUsages = await repositoryCollection.assetRenditionRepository.getUsedIn(
      asset.id,
      existingRenditionId,
    );

    return renditionUsages.some(
      (usage) =>
        usage._id.itemId !== editedContentItemId.itemId ||
        usage._id.variantId !== editedContentItemId.variantId,
    );
  };

  const createRendition = async () => {
    if (!transformation) {
      return;
    }

    const requestModel = mapImageTransformationToRequestServerModel(transformation);
    const response = await repositoryCollection.assetRenditionRepository.createAssetRendition(
      asset.id,
      requestModel,
    );
    const assetRendition = mapAssetRenditionFromServerModel(response);

    dispatch(assetRenditionCreated(assetRendition));
    onCreated(assetRendition.renditionId);
  };

  const updateRendition = async (existingRenditionId: Uuid) => {
    if (!transformation) {
      return;
    }

    const requestModel = mapImageTransformationToRequestServerModel(transformation);
    const response = await repositoryCollection.assetRenditionRepository.updateAssetRendition(
      asset.id,
      existingRenditionId,
      requestModel,
    );
    const assetRendition = mapAssetRenditionFromServerModel(response);

    dispatch(assetRenditionUpdated(assetRendition));
    dispatch(revalidateAssetAndRichTextElements());
    onUpdated();
  };

  const onDialogSubmitted = async () => {
    if (isSaving) {
      return;
    }

    try {
      setIsSaving(true);
      setErrorMessage(null);

      if (
        existingRendition &&
        !(await isRenditionReusedOutsideEditedItem(existingRendition.renditionId))
      ) {
        await updateRendition(existingRendition.renditionId);
      } else {
        await createRendition();
      }

      dispatch(trackUserEvent(TrackedEvent.AssetRenditionSaved));
      setIsSaving(false);
    } catch (error) {
      const isDowngradedPlan =
        tryParseApiError(error)?.code === ServerApiErrorCode.MissingPaidFeature;

      logErrorToMonitoringTool('AssetRenditionDialog.tsx: Saving asset rendition failed', error);
      setIsSaving(false);
      setErrorMessage(
        isDowngradedPlan
          ? 'Looks like advanced asset management is no longer available for your project. Contact your manager if you’d like to edit this image.'
          : 'Something went wrong. Please check your internet connection. If this keeps happening, let us know.',
      );
    }
  };

  const removeAction: IDialogActionWithMoreOptions | undefined = existingRendition
    ? {
        onClick: () => setShowRemoveConfirmation(true),
        text: 'Delete customized image',
        destructive: true,
        disabled: !transformation,
        tooltipText: transformation
          ? ''
          : 'Wait for asset details to load before deleting customized image.',
        ...getDataUiActionAttribute(DataUiAssetRenditionAction.DeleteCustomizedImage),
      }
    : undefined;

  const disabledTooltipText = getDisabledTooltipText(areDimensionsValid, transformation);

  return (
    <FullScreenModalDialog
      state={isSaving ? DialogState.InProgress : DialogState.Default}
      {...getDataUiElementAttribute(DataUiElement.AssetRenditionDialog)}
      headline={`Customize image ${asset.title ?? asset.filename} for this item`}
      isDismissable={!isSaving}
      shouldCloseOnInteractOutside={always(false)}
      isOpen={isOpen}
      onClose={onClose}
      primaryAction={{
        onClick: onDialogSubmitted,
        disabled: !!disabledTooltipText,
        tooltipText: disabledTooltipText,
        text: isSaving ? 'Saving...' : 'Save',
        ...getDataUiActionAttribute(DataUiAction.Save),
      }}
      cancelAction={{
        onClick: onClose,
        tooltipText: 'Cancel',
        tooltipShortcuts: ShortcutSymbols.Escape,
        ...getDataUiActionAttribute(DataUiAction.Cancel),
      }}
      extraAction={removeAction}
      renderNotificationBar={
        errorMessage
          ? () => (
              <NotificationBarAlert onDismiss={() => setErrorMessage(null)}>
                {errorMessage}
              </NotificationBarAlert>
            )
          : undefined
      }
    >
      {transformation ? (
        <>
          <ImageTransformationEditor
            image={asset}
            onValidityChange={setAreDimensionsValid}
            transformation={transformation}
            onChange={setTransformation}
            limitations={limitations}
          />
          <AssetRenditionRemoveConfirmationDialog
            isOpen={showRemoveConfirmation}
            onClose={() => setShowRemoveConfirmation(false)}
            onConfirm={() => {
              setShowRemoveConfirmation(false);
              onRemove();
            }}
          />
        </>
      ) : (
        <Loader />
      )}
    </FullScreenModalDialog>
  );
};

AssetRenditionDialog.displayName = 'AssetRenditionDialog';

const createInitialTransformation = (
  asset: IAsset | null,
  maxDimensionsLimits: MaxDimensionsLimits,
): IImageTransformation | null => {
  if (!asset || !isImage(asset)) {
    return null;
  }

  const initialOutputDimensions = createInitialOutputDimensions(asset, maxDimensionsLimits);

  return {
    width: initialOutputDimensions.width,
    height: initialOutputDimensions.height,
    rect: {
      x: asset.width / 2 - initialOutputDimensions.width / 2,
      y: asset.height / 2 - initialOutputDimensions.height / 2,
      width: initialOutputDimensions.width,
      height: initialOutputDimensions.height,
    },
  };
};

const getDisabledTooltipText = (
  areDimensionsValid: boolean,
  transformation: IImageTransformation | null,
): string => {
  if (!areDimensionsValid) {
    return 'Customized dimensions are filled incorrectly.';
  }

  if (!transformation) {
    return 'Wait for asset details to load before submitting changes.';
  }

  return '';
};
