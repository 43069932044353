import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { DateTime } from '../../../../../../_shared/models/DateTime.ts';
import { Task, createTaskFromServerModel } from '../../../../../../_shared/models/Task.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { TasksEventActions } from '../../../../../../_shared/models/events/TaskEventData.type.ts';
import { logErrorToMonitoringTool } from '../../../../../../_shared/utils/logError.ts';
import { ITaskRepository } from '../../../../../../repositories/interfaces/ITaskRepository.type.ts';
import {
  ContentItemEditing_UpdateTask_Failed,
  ContentItemEditing_UpdateTask_Finished,
  ContentItemEditing_UpdateTask_Started,
} from '../../constants/contentItemEditingActionTypes.ts';

const started = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_UpdateTask_Started,
    payload: {
      taskId,
    },
  }) as const;

const failed = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_UpdateTask_Failed,
    payload: {
      taskId,
    },
  }) as const;

const finished = (task: Task) =>
  ({
    type: ContentItemEditing_UpdateTask_Finished,
    payload: {
      task,
    },
  }) as const;

export type UpdateTaskActionsType = ReturnType<typeof failed | typeof finished | typeof started>;

interface IDeps {
  readonly taskRepository: Pick<ITaskRepository, 'modifyTask'>;
  readonly trackUserEvent: TrackUserEventAction;
}

export const updateTaskCreator =
  (deps: IDeps) =>
  (
    taskId: Uuid,
    description: string,
    contributorIds: ReadonlyArray<string>,
    dueDate: DateTime,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
    } = getState();
    if (!editedContentItemVariant) {
      throw InvariantException('updateTask.ts: editedContentItemVariant is falsy');
    }

    dispatch(started(taskId));

    try {
      const updatedTask = await deps.taskRepository.modifyTask(taskId, {
        assignees: contributorIds,
        description,
        due: dueDate?.isValid ? dueDate.value : null,
      });
      const parsedTask = createTaskFromServerModel(updatedTask);

      dispatch(finished(parsedTask));
    } catch (error) {
      logErrorToMonitoringTool('Error during updating a task', error);
      dispatch(failed(taskId));
    }

    dispatch(
      deps.trackUserEvent(TrackedEvent.Tasks, {
        action: TasksEventActions.TaskUpdated,
        workflowStepId: editedContentItemVariant.assignment.workflowStatus.id,
        hasDueDate: dueDate.isValid,
      }),
    );
  };
